html, body, #root {
    height: 100%;
}

.copyright {
    width: 261px;
    height: 24px;
    /* text-base/font-normal */

    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */

    /* Neutral/White */
    color: #d2d2d2;
    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

@media (max-width: 540px) {
    .register-center-margin {
        margin-right: 2rem;
    }
}

@media (max-width: 768px) {
    .register-center-margin {
        margin-right: 4rem;
    }
}

@media (max-width: 992px) {
    .register-center-margin {
        margin-right: 6rem;
    }
}

.footer-link {
    width: 261px;
    height: 24px;
    /* text-base/font-normal */

    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-decoration: none;
    /* identical to box height, or 24px */

    /* Grey */
    color: #d2d2d2;
    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.registration-footer {
    /*display: flex;*/
    /*flex-direction: row;*/
    /*justify-content: space-between;*/
    /*align-items: flex-start;*/
    /*padding: 48px 64px;*/
    /*gap: 685px;*/
    /*max-height: 50px;*/

    /*position: absolute;*/
    /*left: 0%;*/
    /*right: 0%;*/
    /*top: 99.28%;*/
    /*bottom: 0%;*/

    position: fixed;
    width: 100%;
    bottom: 0;

    /* Deck/Blue */

    /*background: #3F85FC;*/
}


.registration-state-text-inactive {
    font-size: .96vw;
    font-style: normal;
    font-weight: 450;
}

.registration-state-text-active {
    font-size: .96vw;
    color: #3F85FC;
    font-style: normal;
    font-weight: 700;
}

.registration-info-panel-title {
    width: 496px;
    height: 108px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 150%;
    /* or 54px */

    display: flex;
    /*align-items: center;*/

    /* Blue/800 */

    color: #1D2C42;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.registration-info-panel-alt-small {
    width: 496px;
    height: 108px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 150%;
    /* or 54px */

    display: flex;
    justify-content: center; /* centers the text horizontally / align-items: center; / centers the text vertically */

    color: #1D2C42;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    word-wrap: break-word; /* allows the text to wrap to a new line if needed */
}

.registration-info-panel-text {
    height: 30px;

    /* Large/Reg */
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;

    color: #9CA3AF;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.registration-form-container {
    background: #FFFFFF;
    box-shadow: 6px 10px 10px rgba(3, 16, 61, 0.22);
    border-radius: 24px;
    border: 2px solid rgba(3, 16, 61, 0.05) !important;
}

.registration-save-continue-button {
    font-family: 'Arial'!important;
    background: #FF8552!important;
    border-radius: 8px;
    border-color: #FF8552 !important;
    width: 90px;
    height: 38px;
}

.go-to-dashboard-button {
    font-family: 'Arial'!important;
    background: #FF8552!important;
    border-radius: 8px;
    border-color: #FF8552 !important;
    width: 148px;
    height: 38px;
}

.registration-container-spacing {
    margin-left: 1rem;
    margin-right: 1rem;
}

.form-section-label {

    width: 230px;
    height: 24px;

    /* Body/Bold */

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;
    text-align: center;
    text-wrap: none !important;

    /* Neutral/800 */

    color: #313842;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.form-label-text {
    width: 291.5px;
    height: 18px;

    /* Small/Reg */

    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */


    /* Neutral/800 */

    color: #313842;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 1;
}

.employee-missing-notification {
    /*background: linear-gradient(87deg, #7998c7 0, #3f5f8f 100%);*/
    /* Rectangle 5629 */

    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;

    /* Deck/Dark Blue */

    border-radius: 12px;


    height: 100%;
    background: #2C53B0 url("../images/missing-data-mask-blue.png") no-repeat;
    background-size: 100%;
}

.aa-missing-notification {
    background: linear-gradient(87deg, #f05050 0, #990000 100%);
}

.back-button {
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    gap: 8px;

    font-family: 'Arial'!important;
    width: 77px;
    height: 38px;

    /* Neutral/White */
    background: #FFFFFF !important;
    border-radius: 8px;

    border-color: #FF8552 !important;
    color: #FF8552 !important;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.brand-text {
    /* Heading */


    width: 118px;
    height: 32px;

    /* Heading/H2 */

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;

    /* Deck/Blue */

    color: #3F85FC;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.navbar-nav > .active > a {
    background-color: #E9F5FF;
}

.dash-tab-active {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 8px 16px;

    width: 113px;
    height: 34px;

    background-color: #E9F5FF;
    border-radius: 8px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.company-info {
    /*height: 100%;*/
    /*background-image: url("../images/background-overlay.png");*/
    /*background-repeat: no-repeat;*/
    /*background-size: 100%;*/
}

.stage-icon {
    /*height: 32px!important;*/
    width: 389px !important;
    padding: 0 !important;
}

.base {
    height: 100%;
}

.dash-tab-active-text {
    width: 81px;
    height: 18px;

    /* Small/Bold */

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */

    text-align: center;

    /* Deck/Blue */

    color: #3F85FC;
}

.dash-tab-inactive-text {
    /* Text */


    width: 79px;
    height: 18px;

    /* Small/Med */

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */

    text-align: center;

    color: #969DA8;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.container.border-bottom {
    border-bottom: 1px solid #3F85FC;
}

.header-company-name {
    /* Text */


    width: 116px;
    height: 18px;

    /* Small/Med */

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */

    align-items: center;
    text-align: right;

    /* Neutral/800 */

    color: #313842;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.reg-employee-list-header {
    /*width: 161px;*/
    height: 24px;

    /* Body/Bold */

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 150% */

    /*align-items: center;*/
    /*text-align: center;*/

    /* Blue/800 */

    color: #1D2C42;


    /* Inside auto layout */

    /*flex: none;*/
    /*order: 0;*/
    /*flex-grow: 0;*/
}

.current-period-header {
    /* Heading */


    width: 420px;
    height: 60px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 150%;
    /* identical to box height, or 60px */

    display: flex;
    align-items: center;

    /* Neutral/800 */
    color: #313842;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.current-period-text {
    /* Cras mattis consectetur purus sit amet fermentum. */
    width: 420px;
    height: 24px;

    /* Body/Reg */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    /* identical to box height, or 150% */
    display: flex;
    align-items: center;

    color: #969DA8;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.card-stats {
    box-sizing: border-box;

    /* Auto layout */

    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*padding: 24px 0px;*/
    /*gap: 8px;*/

    /*width: 269.33px;*/
    /*height: 160px;*/

    /* Neutral/White */

    background: #FFFFFF;
    border: 1px solid #E5E7EB;
    box-shadow: 6px 10px 10px rgba(169, 179, 193, 0.22);
    border-radius: 8px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.card-stats-title {
    width: 269.33px;
    height: 24px;

    /* Body/Med */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    /*display: flex;*/
    align-items: center;
    text-align: center;

    color: #969DA8;


    /* Inside auto layout */
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
}

.card-stats-value {
    /* Heading */
    width: 269.33px;
    height: 32px;

    /* Heading/H2 */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */

    /*display: flex;*/
    align-items: center;
    text-align: center;

    /* Deck/Blue */
    color: #3F85FC;

    /* Inside auto layout */
    flex: none;
    order: 3;
    align-self: stretch;
    flex-grow: 0;
}

.stats-card-icon {
    font-size: 20px;
    background: #E9F5FF;
    border-radius: 50%;
    text-align: center;
    line-height: 20px;
    vertical-align: middle;
    padding: 5px 10px;
}

/* Dashboard - Payout Reports */

.payout-reports-header {
    /* Heading */
    width: 195px;
    height: 32px;

    /* Heading/H2 */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;

    /* Neutral/800 */
    color: #313842;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}



.payout-reports-empty-text {
    /* Heading */

    width: 332px;
    height: 30px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    /* identical to box height, or 30px */

    text-align: center;

    /* Neutral/Gray 500 */

    color: #6B7280;

    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 0;
}

.payout-reports-accordion {

    /* Auto layout */

    /* Neutral/White */

    /*background: #FFFFFF;*/
    /*border-width: 1px 1px 0px 1px;*/
    /*border-style: solid;*/
    /*border-color: #E5E7EB;*/
    /*border-radius: 8px;*/

    /* Inside auto layout */
    /*flex: none;*/
    /*box-shadow: 6px 10px 10px rgba(169, 179, 193, 0.22);*/
}

.payout-reports-accordion-header-text {

    width: 1176px;
    height: 24px;

    /* Body/Med */

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    /* identical to box height, or 150% */


    /* Neutral/800 */

    color: #313842;
    /* Inside auto layout */
}

.payout-reports-accordion-body-label-text {
    width: 272px;
    height: 18px;

    /* Small/Med */

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    /* identical to box height, or 129% */


    /* Neutral/800 */

    color: #313842;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.payout-reports-advance-text {
    width: 241.6px;
    height: 24px;

    /* Body/Med */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 150% */

    /* Neutral/800 */
    color: #313842;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 1;
}

.notification-banner-button {


    /* Auto layout */

    /*width: 210px;*/
    /*height: 40px;*/
    align-content: center;
    align-items: center;

    background: rgba(255, 255, 255, 0.06);
    /* Neutral/White */

    border: 1.5px solid #FFFFFF;
    backdrop-filter: blur(2px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 8px;

    /* Inside auto layout */
}

.systems-check-header {
    width: 564px;
    height: 32px;

    /* Heading/H2 */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */

    text-align: center;
    /* Neutral/800 */
    color: #313842;
    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.systems-check-body {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* or 129% */
    text-align: center;
    /* Blue/800 */
    color: #13293D;
}

.self-serve-text {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #13293D;
}

.systems-check-icon {
    width: 180px;
    height: 161px;

    /* Update Box */

    filter: drop-shadow(0px 6px 32px rgba(3, 16, 61, 0.1));

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.systems-check-success-icon {
    width: 180px;
    height: 180px;

    /* Update Box */

    filter: drop-shadow(0px 6px 32px rgba(3, 16, 61, 0.1));

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.employee-edit-input {
    height: 33px;
}

.employee-edit-save-btn {
    background: linear-gradient(121.82deg, #FFA783 19.17%, #FF8552 77.79%);
    border-radius: 8px;
    border-color: #FF8552 !important;
    height: 33px;

    /* Auto layout */
    display: inline-flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

}

.employee-list-column-spacer {
    width: 11.11111111% !important;
}

.employee-edit-column-spacer {
    width: 8.333333% !important;
}

.spacer-small {
    width: 4.333333% !important;
}

.employee-edit-cancel-btn {
    /* Auto layout */
    display: inline-flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 8px !important;
    height: 33px !important;

    /* Neutral/White */
    background: #FFFFFF !important;
    border-radius: 8px !important;
    border-color: #FF8552 !important;
    color: #FF8552 !important;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.banner-btn-location {
    align-items: center;
    display: inline-flex;
    justify-content: end;
}

.banner-btn-text {
    /* Text */
    width: 160px;
    height: 18px;

    /* Small/Med */
    font-family: 'Arial';
    font-style: normal;
    font-weight: 200;
    font-size: 13px;
    line-height: 18px;
    /* identical to box height, or 129% */

    /* Neutral/White */
    color: #FFFFFF;
}

.banner-btn {
    background: rgba(255, 255, 255, 0.06)!important;
    border: 1px solid #FFFFFF!important;
    align-items: center;
    backdrop-filter: blur(2px)!important;
    align-content: center;
}
